// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import {Howl, Howler} from 'howler';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import Notifications from "./views/dashboard/component/NotificationPlugin";

//axios here //
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(Notifications);
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios);
axios.defaults.withCredentials = false;


//Vue.use(require('vue-cookies'))
/*const transport = axios.create({
  withCredentials: true
}) */


//axios here //
Vue.use(VueMaterial)


Vue.config.productionTip = false


import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

new Vue({
  router,
  store,
  Notifications,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
