import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      redirect: '/login',
      children: [
        // Dashboard
        {
          name: '',
          path: 'dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Customers
        {
          name: '',
          path: 'customers',
          component: () => import('@/views/dashboard/pages/Customers'),
        },
        {
          name: '',
          path: 'calls',
          component: () => import('@/views/dashboard/pages/Calls'),
        },
        {
          name: '',
          path: 'payments',
          component: () => import('@/views/dashboard/pages/Payments'),
        },
        {
          name: '',
          path: 'eliminate',
          component: () => import('@/views/dashboard/pages/Eliminate'),
        },
      ]
    },

    {
      path: '/login',
      component: () => import('@/views/dashboard/pages/Login'),
    },

    {
      path: '/soon',
      component: () => import('@/views/dashboard/pages/soon'),
    },
    {
      path: '/*',
      component: () => import('@/views/dashboard/Index'),
      redirect: '/dashboard',
    },

  ],
})



